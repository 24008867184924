import { NewsArticle, WithContext } from 'schema-dts';
import { Image } from './types';

type CreateArticleProps = {
  title: string;
  description: string;
  url: string;
  date: string;
  image: Image;
  name: string;
  logo: string;
};

export const createArticle = ({
  title,
  description,
  url,
  date,
  image,
  name,
  logo,
}: CreateArticleProps): WithContext<NewsArticle> => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': url,
  },
  headline: title,
  image: {
    '@type': 'ImageObject',
    url: image.url,
    height: image.height,
    width: image.width,
  },
  datePublished: date,
  dateModified: date,
  author: [{ '@type': 'Organization', name }],
  publisher: {
    '@type': 'Organization',
    name,
    logo,
  },
  description,
});

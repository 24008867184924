import React, { FC } from 'react';
import Text from '@kastle/ui/src/atoms/Text/Text';
import Article from '@kastle/ui/src/sections/ArticleDetail/ArticleDetail';
import { LabelTypes } from '@kastle/ui/src/atoms/Label/Label.types';
import {
  ContentItem,
  ContentList,
} from '@kastle/ui/src/molecules/ContentDisplay/ContentList';
import Label from '../Label/label';

import { Tag } from '../../types/tags';
import { Category } from '../../types/category';

type Props = {
  formattedDate: string;
  tags: Tag[];
  categories: Category[];
};

const ArticleContent: FC<Props> = ({
  children,
  formattedDate,
  tags,
  categories,
}) => (
  <Article>
    <Article.Content>
      <Text>{formattedDate}</Text>
    </Article.Content>
    <Article.Content>{children}</Article.Content>
    <Article.Content>
      <ContentList>
        {tags?.map((tag) => (
          <ContentItem key={`${tag.link}-${tag.name}`}>
            <Label link={tag.link}>{tag.name}</Label>
          </ContentItem>
        ))}
      </ContentList>
    </Article.Content>
    <Article.Content>
      <ContentList>
        {categories?.map((category) => (
          <ContentItem key={`${category.link}-${category.name}`}>
            <Label link={category.link} variantType={LabelTypes.Secondary}>
              {category.name}
            </Label>
          </ContentItem>
        ))}
      </ContentList>
    </Article.Content>
  </Article>
);

export default ArticleContent;

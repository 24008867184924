import React, { FC } from 'react';
import { CommonLayout } from './Common';
import withI18next from '../../../../i18n/I18n';

const Article: FC = ({ children, ...props }) => {
  return (
    <CommonLayout {...props} useSEO={false}>
      {children}
    </CommonLayout>
  );
};

export default withI18next(Article);

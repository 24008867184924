import React, { FC } from 'react';

const Content: FC = ({ children }) => <div className="mt-4">{children}</div>;

const Article: FC & { Content: FC } = ({ children }) => (
  <article className="break-words ArticleDetail__max-width">{children}</article>
);

Article.Content = Content;

export default Article;

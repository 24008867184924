import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import ArticleLayout from '../components/Layout/Article';
import ArticleContent from '@kastle/components/src/components/Article/ArticleContent';
import ArticleSEO from '../components/SEO/Article';

const BlogPost = props => {
  const {
    pageContext,
    data: { post },
  } = props;
  const image = post.frontmatter.thumbnail &&
    post.frontmatter.thumbnail.childImageSharp.original && {
      ...post.frontmatter.thumbnail.childImageSharp.original,
      url: post.frontmatter.thumbnail.childImageSharp.original.src,
    };

  return (
    <ArticleLayout {...props} pageContext={pageContext}>
      <ArticleSEO
        description={post.excerpt}
        lang={pageContext.langKey}
        title={post.frontmatter.title}
        path={post.fields.path}
        date={post.frontmatter.date}
        image={image}
        tags={post.frontmatter.tags}
        categories={post.frontmatter.categories}
      />
      <ArticleContent
        formattedDate={pageContext.formattedDate}
        tags={post.fields.tags}
        categories={post.fields.categories}
      >
        <MDXRenderer>{post.body}</MDXRenderer>
      </ArticleContent>
    </ArticleLayout>
  );
};

export const pageQuery = graphql`query projectQuery($slug: String!) {
  post: mdx(fields: {slug: {eq: $slug}}) {
    fields {
      slug
      path
      categories {
        name
        link
      }
      tags {
        name
        link
      }
    }
    excerpt
    timeToRead
    body
    frontmatter {
      title
      tags
      categories
      thumbnail {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
          original {
            src
            height
            width
          }
        }
      }
      date
      template
    }
  }
}
`;

export default BlogPost;

import React, { FC } from 'react';
import { Link } from 'gatsby';

import LabelComponent from '@kastle/ui/src/atoms/Label/Label';
import { LabelTypes } from '@kastle/ui/src/atoms/Label/Label.types';

type Props = {
  link: string;
  variantType?: LabelTypes;
};

const Label: FC<Props> = ({ children, link, variantType }) => (
  <LabelComponent as={Link} to={link} variantType={variantType}>
    {children}
  </LabelComponent>
);

export default Label;
